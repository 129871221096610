'use client';

import useOneTimeDisplay from '@frontend/client/hooks/useOneTimeDisplay';
import { useSignedPreferences } from '@frontend/client/hooks/useSignedPreferences';
import {
  FullScreenDialog,
  FullScreenDialogHeaderHeight,
} from '@frontend/components/FullScreenDialog';
import visual3 from '@frontend/public/assets/images/visuals/wallet-connect-visual3.svg';
import { isMobile } from '@frontend/utils/isMobile';
import { cn } from '@frontend/utils/tailwindUtil';
import { Button, Flex, Text } from '@radix-ui/themes';
import Image from 'next/image';
import { PropsWithChildren, useState } from 'react';
import { useAccount } from 'wagmi';
import { PaymentPreferencesSection } from '../layout/PaymentPreferencesSection';
import { useYodlStore } from '@frontend/client/contexts/useYodlStore';

type UserStatePopupProviderProps = PropsWithChildren<{}>;

const IntroPopup = ({
  onGetStarted,
  isMobile,
}: {
  onGetStarted: () => void;
  isMobile: boolean;
}) => (
  <div
    style={{
      height: isMobile
        ? `calc(100dvh - ${FullScreenDialogHeaderHeight}px)`
        : '650px',
    }}
    className="flex flex-col justify-between items-center"
  >
    <div className="flex flex-col gap-6 text-center mt-20">
      <Flex justify="center" align="center">
        <Image src={visual3} alt="Wallet Connect" className="-translate-x-14" />
      </Flex>
      <Text size="6" mx="9" className="text-white">
        Set your payment preferences
      </Text>
      <Text size="2" className="text-white/80">
        Choose the tokens you always receive.
      </Text>
    </div>

    <div className={cn(isMobile ? 'mt-auto' : 'mt-10', 'mb-6 lg:mb-4')}>
      <Button
        onClick={onGetStarted}
        radius="full"
        size="4"
        variant="solid"
        color="gray"
        highContrast
      >
        <Text size="2">Get Started</Text>
      </Button>
    </div>
  </div>
);

const UserStatePopupProvider = ({ children }: UserStatePopupProviderProps) => {
  const [showIntroPopup, setShowIntroPopup] = useState(true);
  const hideUserPopups = useYodlStore((state) => state.hideUserPopups);

  const { address } = useAccount();
  const { preferences, isFetched } = useSignedPreferences({
    addressOrEns: address,
  });

  const { isVisible, handleDismiss } = useOneTimeDisplay({
    uniqueId: `user-state-popup-preferences-${address}`,
    shouldDisplay: !!address && isFetched && !preferences && !hideUserPopups,
  });

  return (
    <>
      {children}

      <FullScreenDialog
        open={isVisible}
        handleOnClose={handleDismiss}
        className={cn(showIntroPopup && 'bg-[#3E63DD]')}
        isCustomBg={showIntroPopup}
      >
        {showIntroPopup ? (
          <IntroPopup
            onGetStarted={() => setShowIntroPopup(false)}
            isMobile={isMobile}
          />
        ) : (
          <PaymentPreferencesSection handleOnClose={handleDismiss} />
        )}
      </FullScreenDialog>
    </>
  );
};

export default UserStatePopupProvider;
