import { Button, Flex, Grid, Text } from '@radix-ui/themes';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import {
  WalletCoinbase,
  WalletPhantom,
  WalletWalletConnect,
} from '@web3icons/react';
import { isEmpty } from 'lodash';
import React, { FC, useState } from 'react';
import { useConnect } from 'wagmi';
import { coinbaseWallet } from 'wagmi/connectors';

// Types
interface WalletConnectSectionProps {
  isReceiveContent?: boolean;
}

interface WalletButtonProps {
  icon: React.ReactNode;
  name: React.ReactNode;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

// Helper functions
const getPhantomURL = () => {
  const url = encodeURIComponent(window.location.href);
  const ref = encodeURIComponent('https://yodl.me');
  return `https://phantom.app/ul/browse/${url}?ref=${ref}`;
};

// Reusable Components
const WalletButton: FC<WalletButtonProps> = ({
  icon,
  name,
  onClick,
  loading = false,
  disabled = false,
  className = '',
}) => (
  <Button
    className={`!py-7 shadow-lg ${className}`}
    size="2"
    style={{ width: '100%' }}
    radius="large"
    color="gray"
    variant="outline"
    onClick={onClick}
    loading={loading}
    disabled={disabled}
  >
    <Flex align="center" gap="2" className="w-full">
      {icon}
      <Text size="3" weight="medium">
        {name}
      </Text>
    </Flex>
  </Button>
);

const SectionHeader: FC<{ title: string; subtitle?: string }> = ({
  title,
  subtitle,
}) => (
  <Flex py="2">
    <Text size="1" mr="1" weight="medium">
      {title}
    </Text>
    {subtitle && (
      <Text size="1" color="gray">
        {subtitle}
      </Text>
    )}
  </Flex>
);

// Main Component
const WalletConnectSection: FC<WalletConnectSectionProps> = () => {
  const { connectors, connectAsync } = useConnect();
  const [pendingConnector, setPendingConnector] = useState<string | null>(null);

  const injectedConnectors = connectors
    .filter((c) => c.type === 'injected' && c.isAuthorized)
    .reduce(
      (unique, connector) => {
        const existing = unique.find((c) => c.name === connector.name);
        if (!existing || (!existing.icon && connector.icon)) {
          if (existing) {
            unique = unique.filter((c) => c.name !== existing.name);
          }
          unique.push(connector);
        }
        return unique;
      },
      [] as (typeof connectors)[number][],
    );

  const handleSmartWalletConnect = async () => {
    setPendingConnector('coinbase');
    try {
      await connectAsync({
        connector: coinbaseWallet({
          appName: 'yodl',
          preference: 'smartWalletOnly',
        }),
      });
    } catch (error) {
      // Clear pending state if connection fails or is cancelled
      setPendingConnector(null);
    }
  };

  const handlePhantomConnect = () => {
    setPendingConnector('phantom');
    const phantomWindow = window.open(getPhantomURL());

    // Clear pending state if Phantom window is closed
    const checkWindow = setInterval(() => {
      if (phantomWindow?.closed) {
        clearInterval(checkWindow);
        setPendingConnector(null);
      }
    }, 1000);

    // Cleanup interval after 5 minutes
    setTimeout(() => {
      clearInterval(checkWindow);
      setPendingConnector(null);
    }, 300000);
  };

  return (
    <Grid pb="3">
      <Grid gap="5">
        {/* Smart Wallets Section */}
        <Grid gap="2">
          <SectionHeader
            title="Smart Wallets:"
            subtitle="gas free, best UX, 30s setup"
          />
          <WalletButton
            icon={<WalletCoinbase size={32} />}
            name={
              <>
                Smart Wallet
                <Text size="1" weight="medium" ml="1">
                  by Coinbase
                </Text>
              </>
            }
            onClick={handleSmartWalletConnect}
            loading={pendingConnector === 'coinbase'}
            disabled={
              pendingConnector !== null && pendingConnector !== 'coinbase'
            }
          />
        </Grid>

        {/* Existing Wallets Section */}
        <Grid gap="2">
          <SectionHeader title="Existing Wallets" />

          <ConnectButton.Custom>
            {({ openConnectModal }) => (
              <WalletButton
                icon={<WalletWalletConnect size={32} />}
                name="Wallet Connect"
                onClick={() => {
                  setPendingConnector('walletconnect');
                  const cleanup = () => setPendingConnector(null);

                  // Create a MutationObserver to detect when the modal is removed from DOM
                  const observer = new MutationObserver((mutations) => {
                    mutations.forEach((mutation) => {
                      mutation.removedNodes.forEach((node) => {
                        if ((node as Element).classList?.contains('rk-modal')) {
                          cleanup();
                          observer.disconnect();
                        }
                      });
                    });
                  });

                  // Start observing the document body for removed nodes
                  observer.observe(document.body, {
                    childList: true,
                    subtree: true,
                  });

                  // Cleanup after 5 minutes or if window loses focus
                  window.addEventListener('blur', cleanup, { once: true });
                  const timeoutId = setTimeout(() => {
                    cleanup();
                    observer.disconnect();
                  }, 300000);

                  openConnectModal();
                }}
                loading={pendingConnector === 'walletconnect'}
                disabled={
                  pendingConnector !== null &&
                  pendingConnector !== 'walletconnect'
                }
              />
            )}
          </ConnectButton.Custom>
        </Grid>

        {/* Mobile Wallet Section - Only show if Phantom isn't already available as an injected wallet */}
        {!injectedConnectors.some((c) => c.name === 'Phantom') && (
          <Grid gap="2" className="sm:!hidden">
            <SectionHeader title="Open in Wallet" />

            <WalletButton
              icon={
                <WalletPhantom
                  style={{ color: 'var(--accent-12' }}
                  size={32}
                  variant="mono"
                />
              }
              name="Phantom"
              onClick={handlePhantomConnect}
              loading={pendingConnector === 'phantom'}
              disabled={
                pendingConnector !== null && pendingConnector !== 'phantom'
              }
            />
          </Grid>
        )}

        {/* Injected Wallets Section */}
        {!isEmpty(injectedConnectors) && (
          <Grid gap="2">
            <SectionHeader title="Open in browser wallet" />

            {injectedConnectors.map((connector) => (
              <WalletButton
                key={connector.id}
                icon={
                  connector.icon && (
                    <img
                      src={connector.icon}
                      alt={connector.name}
                      width={32}
                      height={32}
                    />
                  )
                }
                name={connector.name}
                onClick={() => {
                  setPendingConnector(connector.id);
                  connectAsync({ connector }).catch(() => {
                    // Clear pending state if connection fails or is cancelled
                    setPendingConnector(null);
                  });
                }}
                loading={pendingConnector === connector.id}
                disabled={
                  pendingConnector !== null && pendingConnector !== connector.id
                }
              />
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default WalletConnectSection;
