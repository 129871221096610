import { ENS_TLDS } from '@frontend/common/constants/ENS_TLDS';
import { useSearchSubnames } from '@justaname.id/react';
import { useQuery } from '@tanstack/react-query';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useMemo } from 'react';
import { isAddress, zeroAddress } from 'viem';
import { SubnameResponse } from '@justaname.id/sdk';
import { useDebounce } from '.';

// TODO: Move all this to a common types file
type DnsProviders =
  | 'yodlResponse'
  | 'ensResponse'
  | 'ensGenericResponse'
  | 'unstoppableResponse'
  | 'boxResponse'
  | 'freenameResponse';
type SearchResponse = {
  [key in DnsProviders]: {
    address: string;
    avatar: string;
    identifier: string;
    source: string;
    taken: boolean;
  } | null;
};

export interface ProcessedResponse {
  source: string;
  suffix?: string;
  identifier: string;
  address?: string;
  avatarUrl?: string;
  isTaken: boolean;
  isLoading?: boolean;
}

// TODO: Move this to a common utility file
const buildApiUrl = (path: string, params: Record<string, string>): string => {
  return `${path}?${new URLSearchParams(params).toString()}`;
};

const processSourceData = ({
  data,
  key,
  identifier,
}: {
  data: SearchResponse;
  key: DnsProviders;
  identifier: string;
}): ProcessedResponse => {
  const sourceData = data[key];
  const defaultData = PLACEHOLDER_DATA[key];

  if (!sourceData) {
    return {
      ...defaultData,
      identifier,
      isLoading: false,
      address: undefined,
    };
  }

  return {
    ...sourceData,
    isTaken: sourceData.taken,
    isLoading: false,
    address: sourceData.address,
  };
};

// TODO: Move this to a common utility file
const fetchIdentifier = async (identifier: string, provider: DnsProviders) => {
  try {
    // Sometimes happens when the input is empty
    if (!identifier) throw new Error('Identifier is empty, skipping fetch');

    const url = buildApiUrl(`/search`, { identifier, provider });
    const response = await fetch(url, {
      headers: { accept: 'application/json' },
      method: 'GET',
    });

    if (!response.ok) throw new Error(`Failed to fetch data from ${provider}`);

    return (await response.json()) as SearchResponse;
  } catch (error) {
    console.error(error);
    return {
      [provider]: null,
    } as SearchResponse;
  }
};

type PlaceHolderData = {
  [key in DnsProviders]: ProcessedResponse;
};
const PLACEHOLDER_DATA: PlaceHolderData = {
  yodlResponse: {
    source: 'yodl',
    suffix: '',
    identifier: '',
    isTaken: false,
    isLoading: true,
  },
  unstoppableResponse: {
    source: 'unstoppable',
    suffix: '.crypto',
    identifier: '',
    isTaken: false,
    isLoading: true,
  },
  freenameResponse: {
    source: 'freename',
    suffix: '.hodl',
    identifier: '',
    isTaken: false,
    isLoading: true,
  },
  ensGenericResponse: {
    source: 'ens',
    suffix: '', // No suffix for generic ENS
    identifier: '',
    isTaken: false,
    isLoading: true,
  },
  ensResponse: {
    source: 'ens',
    suffix: '.eth',
    identifier: '',
    isTaken: false,
    isLoading: true,
  },
  boxResponse: {
    source: 'box',
    suffix: '.box',
    identifier: '',
    isTaken: false,
    isLoading: true,
  },
} as const;

export const isEnsProvider = ({ identifier }: { identifier?: string }) => {
  return ENS_TLDS.some((tld) => identifier?.endsWith(tld));
};

// Useful to use if we want to filter out
export const isAddressOrEnsProvider = (addressOrEns?: string) => {
  if (!addressOrEns) return false;
  return isAddress(addressOrEns) || isEnsProvider({ identifier: addressOrEns });
};

/**
 * @description - This hook is used inside the CMDK component to handle the search functionality.
 * 1. It uses the useForm hook from react-hook-form to handle the form values.
 * 2. It uses the useDebounce hook to debounce the search input.
 * 3. It uses query to fetch the search results from the server.
 * 4. Parses the search results and returns the form values.
 */
const useENSSearch = ({
  identifier,
  enabled = true,
}: {
  identifier: string;
  enabled?: boolean;
}) => {
  const posthog = usePostHog();

  // Lots of queries to fetch the search results. Each query fetches the search results from a different provider.
  const yodl = useQuery({
    queryKey: ['search', identifier, 'yodl'],
    queryFn: async () =>
      await fetchIdentifier(identifier, 'yodlResponse').then((data) =>
        processSourceData({ data, key: 'yodlResponse', identifier }),
      ),
    staleTime: Infinity,
    placeholderData: {
      ...PLACEHOLDER_DATA.yodlResponse,
      identifier,
    },
    retry: false,
    enabled,
  });
  const ensEth = useQuery({
    queryKey: ['search', identifier, 'ens'],
    queryFn: async () =>
      await fetchIdentifier(identifier, 'ensResponse').then((data) =>
        processSourceData({ data, key: 'ensResponse', identifier }),
      ),
    staleTime: Infinity,
    placeholderData: {
      ...PLACEHOLDER_DATA.ensResponse,
      identifier,
    },
    retry: false,
    enabled,
  });

  const ensGeneric = useQuery({
    queryKey: ['search', identifier, 'generic'],
    queryFn: async () =>
      await fetchIdentifier(identifier, 'ensGenericResponse').then((data) =>
        processSourceData({ data, key: 'ensGenericResponse', identifier }),
      ),
    staleTime: Infinity,
    placeholderData: {
      ...PLACEHOLDER_DATA.ensGenericResponse,
      identifier,
    },
    retry: false,
    enabled,
  });

  const unstoppable = useQuery({
    queryKey: ['search', identifier, 'unstoppable'],
    queryFn: async () =>
      await fetchIdentifier(identifier, 'unstoppableResponse').then((data) =>
        processSourceData({ data, key: 'unstoppableResponse', identifier }),
      ),
    staleTime: Infinity,
    placeholderData: {
      ...PLACEHOLDER_DATA.unstoppableResponse,
      identifier,
    },
    retry: false,
    enabled,
  });

  const freename = useQuery({
    queryKey: ['search', identifier, 'freename'],
    queryFn: async () =>
      await fetchIdentifier(identifier, 'freenameResponse').then((data) =>
        processSourceData({ data, key: 'freenameResponse', identifier }),
      ),
    staleTime: Infinity,
    placeholderData: {
      ...PLACEHOLDER_DATA.freenameResponse,
      identifier,
    },
    retry: false,
    enabled,
  });

  const box = useQuery({
    queryKey: ['search', identifier, 'box'],
    queryFn: async () =>
      await fetchIdentifier(identifier, 'boxResponse').then((data) =>
        processSourceData({ data, key: 'boxResponse', identifier }),
      ),
    staleTime: Infinity,
    placeholderData: {
      ...PLACEHOLDER_DATA.boxResponse,
      identifier,
    },
    retry: false,
    enabled,
  });

  const debouncedIdentifier = useDebounce(identifier, 500);
  const { subnames, isSubnamesLoading } = useSearchSubnames({
    name: debouncedIdentifier.debouncedValue,
  });

  const matchesFromApi = useMemo(() => {
    const dnsMatches = {
      yodl,
      ensEth,
      unstoppable,
      freename,
      ensGeneric,
      box,
    };

    // Remove undefined values and duplicates
    return Object.values(dnsMatches)
      .map((match) => match.data)
      .filter((match) => match !== undefined)
      .reduce((acc, match) => {
        const { address, source } = match;

        const isUnique = !acc.some(
          (value) => value?.address === address && value.source === source,
        );

        if (isUnique) {
          acc.push(match);
        }

        return acc;
      }, [] as ProcessedResponse[]);
  }, [yodl, ensEth, unstoppable, freename, ensGeneric, box]);

  // Log the search results to PostHog
  useEffect(() => {
    if (matchesFromApi.length > 0) {
      posthog?.capture('search username', { query: identifier });
    }
  }, [matchesFromApi, posthog, identifier]);

  const matchesTogether = useMemo(() => {
    const mappedSubnames = subnames.domains
      .map((subname) => {
        if (typeof subname === 'string') return undefined;

        const sub = subname as SubnameResponse;

        const address = sub.records.coins.find(
          (coin) => coin.name === 'eth',
        )?.value;

        return {
          source: 'yodl',
          identifier: subname.ens,
          isTaken: true,
          isLoading: false,
          address,
        } as ProcessedResponse;
      })
      .filter((subname) => subname !== undefined);

    // Combine all matches
    const allMatches = [...matchesFromApi, ...mappedSubnames];

    // Sort to prioritize entries with addresses
    const sortedMatches = allMatches.sort((a, b) => {
      const hasAddressA = Boolean(a.address);
      const hasAddressB = Boolean(b.address);

      if (hasAddressA && !hasAddressB) return -1;
      if (!hasAddressA && hasAddressB) return 1;
      return 0;
    });

    const removedDuplicates = sortedMatches.filter(
      (match, index, array) =>
        array.findIndex(
          (item) =>
            item.address === match.address &&
            item.identifier === match.identifier,
        ) === index,
    );

    const filterValidAddresses = removedDuplicates.filter(
      ({ address }) => address !== zeroAddress,
    );

    return filterValidAddresses.filter((match) => match.isTaken);
  }, [matchesFromApi, subnames]);

  const isLoading = useMemo(() => {
    const dnsMatches = {
      yodl,
      ensEth,
      unstoppable,
      freename,
      ensGeneric,
      box,
    };

    return (
      Object.values(dnsMatches).some((match) => match.isLoading) ||
      isSubnamesLoading
    );
  }, [yodl, ensEth, unstoppable, freename, ensGeneric, box, isSubnamesLoading]);

  return {
    matches: matchesTogether,
    isLoading,
  };
};

export { PLACEHOLDER_DATA, useENSSearch };
