import { PaymentConfig } from '@frontend/client/contexts/useYodlStore';
import { useDebounce } from '@frontend/client/hooks/index';
import {
  PREFERENCES_TEXT_KEY,
  PREFERENCES_TEXT_KEY_LEGACY,
  YODL_ENS_DOMAIN,
} from '@frontend/common/constants';
import {
  useAddSubname,
  usePrimaryName,
  useRecords,
  useUpdateSubname,
} from '@justaname.id/react';
import { useMutation } from '@tanstack/react-query';
import assert from 'assert';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { isAddress } from 'viem';

interface Props {
  addressOrEns?: string;
}

interface Preferences {
  chainIds: number[];
  tokenSymbols: string[];
}

interface SignPreferencesProps {
  username?: string;
  isRegistered?: boolean;
}

const useSignedPreferences = ({ addressOrEns }: Props) => {
  const { debouncedValue } = useDebounce(addressOrEns, 500);
  const [isFetched, setIsFetched] = useState(false);

  // Reset isFetched when addressOrEns changes
  useEffect(() => {
    setIsFetched(false);
  }, [addressOrEns]);

  // If is address, then pull the primaryName
  const { primaryName, isPrimaryNameLoading, isPrimaryNameFetching } =
    usePrimaryName({
      address: isAddress(debouncedValue || '') ? debouncedValue : undefined,
    });

  const { records, isRecordsLoading, isRecordsFetching } = useRecords({
    ens: isAddress(debouncedValue || '') ? primaryName : addressOrEns,
  });

  useEffect(() => {
    // Reset isFetched when fetching starts
    if (isRecordsFetching || isPrimaryNameFetching) {
      setIsFetched(false);
      return;
    }

    // Only set to true when both queries are complete and not in loading state
    if (!isRecordsLoading && !isPrimaryNameLoading) {
      setIsFetched(true);
    }
  }, [
    isRecordsFetching,
    isPrimaryNameFetching,
    isRecordsLoading,
    isPrimaryNameLoading,
  ]);

  const preferences = useMemo(() => {
    const parsePreferences = (preferences: string) => {
      if (isEmpty(preferences)) return undefined;

      try {
        const {
          chainIds = [],
          tokenSymbols = [],
          redirectUrl,
          currency,
          amount,
        } = JSON.parse(preferences);

        // Make sure amount is a number and positive if available
        if (
          amount !== undefined &&
          (typeof amount !== 'number' || amount < 0)
        ) {
          throw new Error('Amount must be a positive number');
        }

        return {
          chainIds,
          tokenSymbols,
          redirectUrl,
          currency,
          amount,
        } as PaymentConfig;
      } catch (error) {
        console.error('Failed to parse preferences:', error);
        return undefined;
      }
    };

    const getPreferencesText = (key: string) =>
      records?.records.texts?.find((text) => text.key === key)?.value;

    const preferencesText =
      getPreferencesText(PREFERENCES_TEXT_KEY) ||
      getPreferencesText(PREFERENCES_TEXT_KEY_LEGACY);

    return parsePreferences(preferencesText || '');
  }, [records]);

  return {
    preferences,
    isLoading: isPrimaryNameLoading || isRecordsLoading,
    isFetching: isPrimaryNameFetching || isRecordsFetching,
    isFetched,
  };
};

const useSignPreferences = ({
  username,
  isRegistered,
}: SignPreferencesProps) => {
  const { addSubname } = useAddSubname();
  const { updateSubname } = useUpdateSubname();

  return useMutation({
    mutationFn: async ({ tokenSymbols, chainIds }: Preferences) => {
      assert(username, 'ENS is required');

      const preferences: Record<string, any> = { tokenSymbols };
      if (chainIds.length > 0) preferences.chainIds = chainIds;

      const text = {
        [PREFERENCES_TEXT_KEY]: JSON.stringify(preferences),
      };

      isRegistered
        ? await updateSubname({
            text,
            ens: username,
          })
        : await addSubname({
            text,
            username,
            ensDomain: YODL_ENS_DOMAIN,
          });
      window?.location.reload();
    },
  });
};

export { useSignedPreferences, useSignPreferences };
