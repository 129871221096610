'use client';

import { useYodlStore } from '@frontend/client/contexts/useYodlStore';
import { useEffect, useRef, useState } from 'react';
import * as Toast from '@radix-ui/react-toast';
import './ToastStyles.css';
import { Card, Text } from '@radix-ui/themes';

export function PaymentNotification() {
  const [open, setOpen] = useState(false);
  const timerRef = useRef(0);

  const paymentNotification = useYodlStore(
    (state) => state.paymentNotification,
  );
  const setPaymentNotification = useYodlStore(
    (state) => state.setPaymentNotification,
  );

  const afterClose = (newState: boolean) => {
    setOpen(newState);
    if (!newState) {
      // remove any stale paymentNotifications from the store
      // note: this will re-render this screen
      setPaymentNotification(null);
    }
  };

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    if (!paymentNotification && !paymentNotification?.invoiceAmount) return;

    setOpen(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      setOpen(true);
    }, 200);
  }, [paymentNotification]);

  if (!paymentNotification) return null;

  return (
    <Toast.Provider swipeDirection="down">
      <Toast.Root className="ToastRoot" open={open} onOpenChange={afterClose}>
        <Toast.Title className="ToastTitle">Payment received</Toast.Title>
        <Toast.Description>
          <Text size="6">
            {paymentNotification.invoiceAmount}{' '}
            {paymentNotification.invoiceCurrency}
          </Text>
        </Toast.Description>
        <Toast.Action
          className="ToastAction"
          asChild
          altText="Goto schedule to undo"
        >
          <button className="Button small green">x</button>
        </Toast.Action>
      </Toast.Root>
      <Toast.Viewport className="ToastViewport" />
    </Toast.Provider>
  );
}
