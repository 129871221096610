'use client';

import {
  isAddressOrEnsProvider,
  isEnsProvider,
} from '@frontend/client/hooks/useENSSearch';
import { cn } from '@frontend/utils/tailwindUtil';
import { usePrimaryName } from '@justaname.id/react';
import { PersonIcon } from '@radix-ui/react-icons';
import { Avatar, AvatarProps, Skeleton } from '@radix-ui/themes';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Address, isAddress } from 'viem';

interface Props {
  addressOrEns?: string | Address;
  fallbackIcon?: ReactElement;
  size?: keyof typeof sizeMap;
  color?: AvatarProps['color'];
  className?: string;
}

const sizeMap = {
  '1': 16,
  '2': 32,
  '3': 48,
  '4': 64,
  '5': 80,
  '6': 96,
  '7': 128,
  '8': 160,
  '9': 192,
} as const;

const UserAvatar: React.FC<Props> = ({
  addressOrEns: address,
  fallbackIcon,
  size = '2',
  color = 'gray',
  className,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const isEns = isAddressOrEnsProvider(address);

  const { primaryName } = usePrimaryName({
    address: address as Address | undefined,
    enabled: !!address && isAddress(address),
  });

  const Fallback = fallbackIcon || (
    <PersonIcon className={cn(`size-[${sizeMap[size]}px]`)} />
  );

  const avatarUrl = useMemo(() => {
    if (!address || !isEns) {
      return undefined;
    }

    let identifier = address;
    if (isAddress(address)) {
      // For addresses, use ENS name if available, otherwise use the address
      identifier = primaryName ?? address;
    }

    return `https://metadata.ens.domains/mainnet/avatar/${identifier}`;
  }, [address, isEns, primaryName]);

  useEffect(() => {
    if (!avatarUrl) {
      setIsLoading(false);
      return;
    }

    const img = new Image();
    img.src = avatarUrl;
    img.onload = () => setIsLoading(false);
    img.onerror = () => setIsLoading(false);

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [avatarUrl]);

  return (
    <Skeleton loading={isLoading}>
      <Avatar
        className={className}
        src={avatarUrl}
        fallback={Fallback}
        radius="full"
        size={size}
        color={color}
        fetchPriority="high"
        style={{ width: sizeMap[size], height: sizeMap[size] }}
      />
    </Skeleton>
  );
};

export default UserAvatar;
