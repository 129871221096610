import { DialogHeader } from '@frontend/components/DialogHeader';
import logoDark from '@frontend/public/assets/images/logo.svg';
import { cn } from '@frontend/utils/tailwindUtil';
import * as Dialog from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/themes';
import Image from 'next/image';
import { FC, ReactNode, useEffect } from 'react';
import AppContainer from './ui/AppContainer';

/**
 * Constants used for dialog dimensions and styling
 */
const DIALOG_CONSTANTS = {
  HEADER_HEIGHT: 100,
  LOGO_WIDTH: 70,
} as const;

/**
 * Props interface for the FullScreenDialog component
 */
export interface FullScreenDialogProps {
  /** Content to be rendered inside the dialog */
  children: ReactNode;
  /** Title text or component to be displayed in the dialog header */
  title?: string | ReactNode;
  /** Controls whether the dialog is visible */
  open: boolean;
  /** Callback fired when the open state changes */
  onOpenChange?: (open: boolean) => void;
  /** Optional callback for custom close handling */
  handleOnClose?: () => void;
  /** Whether to show the close button in header. Defaults to true */
  showClose?: boolean;
  /** Additional CSS classes for the dialog */
  className?: string;
  /** Whether to render as a full-screen page. Defaults to false */
  fullScreenPage?: boolean;
  /** Whether using custom background styling. Defaults to false */
  isCustomBg?: boolean;
  /** Additional CSS classes for the container */
  containerClassName?: string;
}

/** Height constant for the dialog header in pixels */
export const FullScreenDialogHeaderHeight = 10 * 10;

/**
 * A responsive dialog component that can render either as a modal dialog or full-screen page
 *
 * Features:
 * - Responsive design with different layouts for mobile and desktop
 * - Optional full-screen page mode
 * - Customizable header with title and close button
 * - Body scroll lock management on mobile
 * - Backdrop with blur effect for modal mode
 *
 * @example
 * ```tsx
 * <FullScreenDialog
 *   open={isOpen}
 *   onOpenChange={setIsOpen}
 *   title="Dialog Title"
 * >
 *   Dialog content here
 * </FullScreenDialog>
 * ```
 */
export const FullScreenDialog: FC<FullScreenDialogProps> = ({
  children,
  title,
  open,
  onOpenChange,
  handleOnClose,
  showClose = true,
  className,
  fullScreenPage = false,
  isCustomBg = false,
  containerClassName,
}) => {
  useEffect(() => {
    if (open && !fullScreenPage && window.innerWidth < 768) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open, fullScreenPage]);

  const handleCloseClick = () => {
    onOpenChange?.(false);
    handleOnClose?.();
  };

  const pageClass = cn(
    // Mobile styles
    'absolute bg-[var(--color-panel-solid)] pb-4',
    'inset-0 flex flex-col overflow-y-auto',
    'z-50',
    'md:static md:bg-transparent md:inset-auto md:flex-none md:z-auto',
    className,
  );

  const dialogContentClass = cn(
    !fullScreenPage && [
      'fixed bg-[var(--color-panel-solid)]',
      'inset-0 flex flex-col px-4 overflow-y-auto',
      'md:inset-auto md:left-1/2 md:top-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2',
      'md:w-full md:max-w-md md:max-h-[90vh] md:rounded-xl md:shadow-xl md:p-4',
      'z-50',
    ],
    className,
  );

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange} modal={false}>
      {open && !fullScreenPage && (
        <>
          <div className="fixed inset-0 bg-black/50 backdrop-blur transition z-40" />
        </>
      )}

      <Dialog.Content
        className={cn(fullScreenPage ? pageClass : dialogContentClass)}
      >
        <VisuallyHidden>
          <Dialog.Title>
            <Image
              src={logoDark}
              alt="Yodl Logo"
              className={cn(
                'flex mx-auto justify-center items-center',
                isCustomBg && 'brightness-0 invert',
              )}
              width={DIALOG_CONSTANTS.LOGO_WIDTH}
              priority
            />
          </Dialog.Title>
          <Dialog.Description />
          <Dialog.Close />
        </VisuallyHidden>

        <div className={cn('block flex-none', fullScreenPage && 'md:hidden')}>
          <DialogHeader
            title={title}
            showClose={showClose}
            isCustomBg={isCustomBg}
            onClose={handleCloseClick}
            fullScreenPage={fullScreenPage}
          />
        </div>

        {fullScreenPage ? (
          <AppContainer
            className={cn(
              'sm:!mt-6 !mt-0 flex-1 overflow-y-auto',
              containerClassName,
            )}
            containerClassName={containerClassName}
          >
            {children}
          </AppContainer>
        ) : (
          <div className="mt-4 flex-1 overflow-y-auto md:mb-4">{children}</div>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};
