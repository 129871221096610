import { getTokens } from '@yodlpay/tokenlists';
import { Address } from 'viem';
import { Connector } from 'wagmi';
import {
  arbitrum,
  base,
  bsc,
  gnosis,
  mainnet,
  optimism,
  polygon,
} from 'wagmi/chains';
import coinbasewallet from '../../public/assets/images/wallets/coinbasewallet.svg';
import metamask from '../../public/assets/images/wallets/metamask.svg';
import rainbow from '../../public/assets/images/wallets/rainbow.svg';
import walletconnect from '../../public/assets/images/wallets/walletconnect.svg';
import { StrippedConnector, SupportedChainId } from '../types';
import usdtABI from '../usdt.abi.json';

export const SUPPORTED_CHAINS = [
  mainnet,
  polygon,
  // bsc,
  arbitrum,
  optimism,
  // gnosis,
  base,
] as const;

// Recommended order for price
// TODO: When creating a chain selector then let users pick their own list
export const CHAIN_PRICE_ORDER = [
  arbitrum,
  optimism,
  polygon,
  bsc,
  gnosis,
  base,
  mainnet,
];

export const SUPPORTED_TOKENS = getTokens();

export const isSupportedSymbol = (symbol: string) => {
  return SUPPORTED_TOKENS.some((token) => token.symbol === symbol);
};

export const hasSupportedChain = <T extends { chainId?: string | number }>(
  params: T,
): params is T & { chainId: number } =>
  params.chainId !== undefined &&
  SUPPORTED_CHAINS.some(({ id }) => id === Number(params.chainId));

/**
 * Sort an array of any type based on a preferred order array
 * @param arrToSort - The array to be sorted
 * @param preferredOrder - The array specifying the preferred order
 * @returns The sorted array
 */
export function sortByPreferredOrder<T>(
  arrToSort: T[],
  preferredOrder: T[],
): T[] {
  // Create a map for quick look-up of the preferred order indices
  const orderMap = new Map<T, number>();
  preferredOrder.forEach((item, index) => orderMap.set(item, index));

  // Sort the array based on the preferred order indices or fallback to natural order
  return arrToSort.sort((a, b) => {
    const orderA = orderMap.has(a) ? orderMap.get(a)! : Number.MAX_SAFE_INTEGER;
    const orderB = orderMap.has(b) ? orderMap.get(b)! : Number.MAX_SAFE_INTEGER;
    return orderA - orderB;
  });
}

export const SUPPORTED_CHAIN_IDS = SUPPORTED_CHAINS.map(
  (chain) => chain.id,
) as SupportedChainId[];

export const DISCORD = 'https://discord.gg/tKHvxh384Y';
export const TWITTER = 'https://twitter.com/yodlpay';
export const YODL_URL = 'yodl.me';

export const PAYMENTS_PER_PAGE = 10;

export const GOLDSKY_SOURCE_TO_CHAIN_ID: Record<string, number> = {
  'yodl-indexer-mainnet': 1,
  'yodl-indexer-optimism': 10,
  'yodl-indexer-xdai': 100,
  'yodl-indexer-matic': 137,
  'yodl-indexer-arbitrum-one': 42161,
  'yodl-indexer-base': 8453,
};

export const YODL_PRODUCT_NAME = 'YodlPay';
export const YODL_RECEIPT_EMAIL = 'noreply@yodlpay.com';
export const YODL_RECEIPT_ENDPOINT = `https://${YODL_URL}/tx`;
export const EMAIL_TEMPLATE_ALIAS = 'receipt';

export const GNOSIS_MULTICALL_ADDRESS: Address =
  '0xcA11bde05977b3631167028862bE2a173976CA11';

// This will be used to represent the native token on all chains
export const NATIVE_TOKEN_ADDRESS =
  '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' as Address;

// The 'approve' function in the USDT contract has no return types, whereas the ERC20 return type is bool
// This results in an error when simulating the tx.
// Ultimately, this doesn't have an impact on the tx, but we don't want errors being raised unnecessarily.
export const CUSTOM_ABI = {
  USDT: {
    1: usdtABI,
  },
} as { [symbol: string]: { [chainId: number]: any } };

// The maximum number of requests we should make to reach a swap amount out that
// satisfies the invoice
export const MAX_CURVE_ATTEMPTS = 10;

export const MIN_RAW_BALANCE = 0.01;

export const MAX_RAW_BALANCE = 1000000;

// The top 3 currencies are temporarily moved to the top for Devcon
export const CURRENCIES = [
  'THB',
  'EUR',
  'SGD',
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CNY',
  'ILS',
  'IDR',
  'INR',
  'JPY',
  'KRW',
  'GBP',
  'MXN',
  'NZD',
  'PHP',
  'PLN',
  'SEK',
  'TRY',
  'VND',
];

export const CURRENCY_TO_SYMBOL = {
  EUR: { symbol: '€' },
  USD: { symbol: '$' },
  GBP: { symbol: '£' },
  JPY: { symbol: '¥' },
  CNY: { symbol: '¥' },
  KRW: { symbol: '₩' },
  INR: { symbol: '₹' },
  RUB: { symbol: '₽' },
  TRY: { symbol: '₺' },
  BRL: { symbol: 'R$' },
  CAD: { symbol: 'C$' },
  AUD: { symbol: 'A$' },
  NZD: { symbol: 'NZ$' },
  IDR: { symbol: 'Rp' },
  THB: { symbol: '฿' },
  VND: { symbol: '₫' },
  // CHF: { symbol: 'CHF' },
  ETH: { symbol: 'ETH', alt: 'Ξ' },
  WETH: { symbol: 'WETH', alt: 'Ξ' },
  USDT: { symbol: '$' },
  USDC: { symbol: '$' },
  EURe: { symbol: '€' },
  DAI: { symbol: '$' },
};

export const FIAT_PRECISION_DECIMALS = 6;
export const TOKEN_DECIMALS = 6;
export const INVOICE_AMOUNT_DECIMALS = 2; // 0.01 USD gives invoiceAmount = 1
export const INPUT_TO_INVOICE_MULTIPLIER = 10 ** INVOICE_AMOUNT_DECIMALS;

export const ICONS: { [key: string]: string } = {
  metamask: metamask.src,
  walletconnect: walletconnect.src,
  coinbasewallet: coinbasewallet.src,
  rainbow: rainbow.src,
};

export function connectorWalletIcon(
  connector: Connector | StrippedConnector,
): string | undefined {
  return ICONS[connector.id.toLocaleLowerCase()];
}

export * from '@frontend/common/constants/idendity';

export const hasAblyApiKey = !!process.env.NEXT_PUBLIC_ABLY_API_KEY;

export const YODL_ENS_DOMAIN = 'yodl.eth';

// We migrated from me.yodl.preferences to me.yodl, for backwards compatibility
// We will keep the old key for now, but we will eventually remove it
export const PREFERENCES_TEXT_KEY_LEGACY = 'me.yodl.preferences';
export const PREFERENCES_TEXT_KEY = 'me.yodl';

export const ORIGIN = 'https://yodl.me';
export const DOMAIN = 'yodl.me';

export const MAINNET_PROVIDER_URL =
  process.env.NEXT_PUBLIC_MAINNET_PROVIDER_URL ||
  mainnet.rpcUrls.default.http[0];

export * from './preferenceSections';
