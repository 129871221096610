'use client';

import InstallAppSection from '@frontend/app/shared/layout/InstallAppSection';
import { PaymentPreferencesSection } from '@frontend/app/shared/layout/PaymentPreferencesSection';
import { useUserSettingsStore } from '@frontend/client/contexts/useUserSettingsStore';
import useAccountEns from '@frontend/client/hooks/useAccountEns';
import { truncateAddress } from '@frontend/common';
import UserAvatar from '@frontend/components/UserAvatar';
import { chainById } from '@frontend/utils/chains';
import { isMobile, isStandalone } from '@frontend/utils/isMobile';
import {
  ChevronRightIcon,
  ExternalLinkIcon,
  GlobeIcon,
  LockClosedIcon,
  MoonIcon,
  PlusIcon,
  SunIcon,
} from '@radix-ui/react-icons';
import {
  Card,
  Flex,
  Grid,
  SegmentedControl,
  Select,
  Separator,
  Spinner,
  Text,
} from '@radix-ui/themes';
import { useAccountModal, useChainModal } from '@rainbow-me/rainbowkit';
import { useTheme } from 'next-themes';
import Image from 'next/image';
import Link from 'next/link';
import { FC, useState } from 'react';
import { useAccount } from 'wagmi';

type Sections = 'main' | 'paymentPreferences' | 'installApp';

interface UserSettingsSectionProps {
  handleOnClose: () => void;
}

const UserSettingsSection: FC<UserSettingsSectionProps> = ({
  handleOnClose,
}) => {
  const { address } = useAccount();
  const { openAccountModal } = useAccountModal();
  const { primaryName } = useAccountEns();
  const { preferredPrivacy, setPreferredPrivacy } = useUserSettingsStore();
  const { theme, setTheme } = useTheme();

  const isInstallable = isMobile && !isStandalone;

  const [currentSection, setCurrentSection] = useState<Sections>('main');

  return (
    <Grid gap="4">
      {currentSection === 'main' && (
        <>
          <Card
            hidden={currentSection !== 'main'}
            variant="classic"
            size="2"
            className="cursor-pointer"
            onClick={() => {
              if (openAccountModal) {
                openAccountModal();
                setTimeout(() => (document.body.style.pointerEvents = ''), 0);
              }
            }}
          >
            <Flex justify="between" align="center">
              <Flex gap="3" align="center">
                <UserAvatar addressOrEns={address} />
                <Flex direction="column">
                  {(address || primaryName) && (
                    <Text size="2" weight="bold">
                      {primaryName || truncateAddress(address || '', 4)}
                    </Text>
                  )}
                  {primaryName && (
                    <Text size="1" color="gray">
                      {truncateAddress(address || '', 4)}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Card>

          <Separator orientation="horizontal" size="4" className="opacity-20" />

          <Flex direction="column" gap="2">
            {primaryName && (
              <Link
                href={`https://app.justaname.id/?ens=${primaryName}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Card variant="classic" size="2" className="cursor-pointer">
                  <Flex justify="between" align="center">
                    <div>
                      <Text size="2" weight="bold" as="p">
                        Profile
                      </Text>
                      <Text color="gray" size="1" as="p">
                        Manage your ENS profile
                      </Text>
                    </div>
                    <ExternalLinkIcon />
                  </Flex>
                </Card>
              </Link>
            )}

            <Card
              className="cursor-pointer"
              variant="classic"
              size="2"
              onClick={() => {
                setCurrentSection('paymentPreferences');
              }}
            >
              <Flex justify="between" align="center">
                <div>
                  <Text size="2" weight="bold" as="p">
                    Payment Setup
                  </Text>
                  <Text color="gray" size="1" as="p">
                    Set receiving tokens and chains
                  </Text>
                </div>
                <ChevronRightIcon />
              </Flex>
            </Card>
          </Flex>

          {isInstallable && (
            <>
              <Separator
                orientation="horizontal"
                size="4"
                className="opacity-20"
              />

              <Flex direction="column" gap="2">
                <Card
                  onClick={() => {
                    setCurrentSection('installApp');
                  }}
                  variant="classic"
                  size="2"
                  className="cursor-pointer bg-gradient-to-r from-transparent to-[--accent-3]"
                >
                  <Flex justify="between" align="center">
                    <div>
                      <Text size="2" weight="bold" as="p">
                        Install App
                      </Text>
                      <Text color="gray" size="1" as="p">
                        Add yodl to your home screen
                      </Text>
                    </div>
                    <PlusIcon />
                  </Flex>
                </Card>
              </Flex>
            </>
          )}

          {/* Settings */}
          <Separator orientation="horizontal" size="4" className="opacity-20" />
          <Flex direction="column" gap="4">
            <Flex justify="between" align="center">
              <div>
                <Text size="2" weight="bold" as="p">
                  Hide Balances
                </Text>
              </div>
              <SegmentedControl.Root defaultValue={preferredPrivacy}>
                <SegmentedControl.Item
                  value="public"
                  onClick={() => setPreferredPrivacy('public')}
                >
                  <GlobeIcon />
                </SegmentedControl.Item>
                <SegmentedControl.Item
                  value="private"
                  onClick={() => setPreferredPrivacy('private')}
                >
                  <LockClosedIcon />
                </SegmentedControl.Item>
              </SegmentedControl.Root>
            </Flex>

            <Flex justify="between" align="center">
              <div>
                <Text size="2" weight="bold" as="p">
                  Theme
                </Text>
              </div>
              <SegmentedControl.Root defaultValue={theme}>
                <SegmentedControl.Item
                  value="light"
                  onClick={() => setTheme('light')}
                >
                  <SunIcon />
                </SegmentedControl.Item>
                <SegmentedControl.Item
                  value="dark"
                  onClick={() => setTheme('dark')}
                >
                  <MoonIcon />
                </SegmentedControl.Item>
              </SegmentedControl.Root>
            </Flex>
          </Flex>
        </>
      )}

      {/* Sections */}
      {currentSection === 'paymentPreferences' && (
        <PaymentPreferencesSection handleOnClose={handleOnClose} />
      )}
      {currentSection === 'installApp' && <InstallAppSection />}
    </Grid>
  );
};

export default UserSettingsSection;

// not used currently
const ChainSelectBox = () => {
  const { address, chainId } = useAccount();
  const { openChainModal } = useChainModal();
  const { openAccountModal } = useAccountModal();
  const { chainInfo } = chainById(chainId || 1);

  return (
    <Select.Root size="2" value="light">
      <Select.Trigger
        radius="full"
        variant="soft"
        className="!m-0"
        onClick={(event) => {
          if (openChainModal) {
            event.stopPropagation();
            event.preventDefault();
            openChainModal();
            // Hack to access modal inside the other modal - https://github.com/radix-ui/primitives/issues/2122
            setTimeout(() => (document.body.style.pointerEvents = ''), 0);
          }
        }}
      >
        <Flex as="span" align="center" gap="2">
          {chainInfo?.logoUri ? (
            <Image
              src={chainInfo?.logoUri}
              alt="Chain Logo"
              width={24}
              height={24}
            />
          ) : (
            <Spinner size="2" />
          )}
        </Flex>
      </Select.Trigger>
    </Select.Root>
  );
};
