'use client';

import { usePrimaryName } from '@justaname.id/react';
import { useAccount } from 'wagmi';

const useAccountEns = () => {
  const { address } = useAccount();

  return usePrimaryName({
    address,
  });
};

export default useAccountEns;
